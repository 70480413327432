import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { useAuthContext } from './Auth.context'
import { getSections, addSection, removeSection, editSection } from '../routes/sections.routes'
import ConfirmModal from '../modals/Confirm.modal'

interface SectionType {
    sections: any[]
    setSections: Dispatch<SetStateAction<any[]>>
    createSection: (section: any) => Promise<any>
    deleteSection: (section: any) => Promise<any>
    editChangesSection: (section: any) => Promise<any>
    enableConfirmModal: (message: string) => void
}

export const SectionContext = createContext<SectionType>({} as SectionType)

export const SectionProvider = (props: any) => {
    const {isAuth} = useAuthContext()
    const [sections, setSections] = useState<any[]>([])
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [messageConfirmModal, setMessageConfirmModal] = useState<string>('')
    const [sectionToDelete, setSectionToDelete] = useState<any>()

    useEffect(() => {
        if (isAuth) {
            readAllSections()
        }
    }, [isAuth])

    const enableConfirmModal = async () => {
        const section = {...sectionToDelete}
        const response = await removeSection(section)
        const sectionsToEdit: any[] = []
        sections.forEach((s) => {
            if (response.id !== s._id) {
                sectionsToEdit.push(s)
            }
        })
        setSections([...sectionsToEdit])
        setSectionToDelete(undefined)
        return response

    }

    const closeConfirmModal = () => {
        setOpenConfirmModal(false)
    }

    const readAllSections = async () => {
        const response = await getSections()
        console.log(response)
        setSections(response.response)
    }

    const createSection = async (section: any) => {
        const response = await addSection(section)
        console.log([...sections, response.response])
        setSections([...sections, response.response])
        return response
    }

    const editChangesSection = async (section: any) => {
        const response = await editSection(section)
        const sectionsToEdit = [...sections]
        const sectionsEdited = sectionsToEdit.map((s, index) => {
            if (response.response._id === s._id) {
                return response.response
            } else {
                return s
            }
        })
        setSections([...sectionsEdited])
        return response
    }

    const deleteSection = async (section: any) => {
        setSectionToDelete(section)
        setOpenConfirmModal(true)
        setMessageConfirmModal(`Confirme que desea borrar ${section.name}`)
    }

    const values = {
        sections,
        setSections,
        createSection,
        deleteSection,
        editChangesSection,
        enableConfirmModal
    } as SectionType

    return (
        <>
            {openConfirmModal && <ConfirmModal 
                open={openConfirmModal}
                handleClose={closeConfirmModal}
                message={messageConfirmModal}
                confirmButton={enableConfirmModal}
            />}
            <SectionContext.Provider value={values}>
            {props.children}
            </SectionContext.Provider>
        </>
    )
}

export const useSectionContext = () => useContext(SectionContext)