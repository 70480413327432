import axios from 'axios'

const url = window.location.host.split('.')[0].includes('qa')  ? process.env.REACT_APP_SERVER_URL_QA : window.location.host.split('.')[0] === 'app' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST

const getCompanies = async () => {
    const response = await axios.get(url+'/api/organization/getOrganizations')
    return response.data
}

export default {
    getCompanies
}