import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonToolbar } from "@ionic/react"
import { close } from "ionicons/icons"
import { useEffect, useState } from "react"
import { useSectionContext } from "../context/Section.context"

export const AddCategoryModal = ({open, handleClose, sectionIn}: {open: boolean, handleClose: () =>  void, sectionIn?: {_id: string, name: string, description: string, parent: string | any}}) => {
    const {sections, createSection, editChangesSection} = useSectionContext()
    const [section, setSection] = useState<{
        _id?: string
        name: string
        description: string
        parent?: string
    }>({
        name: '',
        description: '',
        parent: 'no'
    })

    useEffect(() => {
        console.log(sections)
        if (sections.length > 0) {
            if (sectionIn) {
                setSection({
                    ...sectionIn,
                    parent: sectionIn.parent ? sectionIn.parent._id : 'no'
                })
            } else {
                setSection({
                    name: '',
                    description: '',
                    parent: 'no'
                })
            }
        }
    }, [sections])

    const createCategory = async () => {
        const response = await createSection(section)
        console.log(response)
        handleClose()
    }

    const changeCategory = async () => {
        const response = await editChangesSection(section)
        console.log(response)
        handleClose()
    }

    const editSection = (e: any) => {
        const sectionCache = {
            ...section,
            [e.target.name]: e.target.value
        }
        setSection(sectionCache)
    }

    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={false}
            className='specialities-management-modal'
        >
            <IonContent className='modalContainer' class='ion-padding' style={{position: 'relative'}}>
            <IonToolbar>
                <div style={{paddingLeft: 15}}>
                    <h3 style={{fontFamily: 'Poppins'}}>{sectionIn ? 'Editar' : 'Agregar'} Categoría</h3>
                </div>
                <IonButtons slot='end'>
                    <IonButton onClick={() => {handleClose()}}>
                        <IonIcon icon={close} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol size='12'>
                            <div style={{borderBottom: '1px solid #5E6F9D', width: '100%'}}></div>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <div style={{padding: 15}}>
                                <p style={{fontSize: 14, color: '#333333', fontFamily: 'Poppins'}}>Nombre</p>
                                <input
                                    name={'name'}
                                    onChange={editSection}
                                    value={section.name}
                                    style={{
                                        border: '1px solid #c4c4c4',
                                        fontSize: 12,
                                        borderRadius: 5,
                                        width: '100%',
                                        paddingLeft: 10,
                                        height: 32}}
                                />
                                <p style={{fontSize: 14, color: '#333333', fontFamily: 'Poppins'}}>Padre</p>
                                <select 
                                    name={'parent'}
                                    onChange={editSection}
                                    value={section.parent}
                                    style={{
                                    width: '100%', 
                                    border: '1px solid #c4c4c4', 
                                    fontSize: 12, 
                                    borderRadius: 5, 
                                    height: 32,
                                    paddingLeft: 10
                                    }}>
                                        <option value={'no'}>
                                            No
                                        </option>
                                        {
                                            sections.map(section => {
                                                return (
                                                    <option key={section.name} value={section._id}>
                                                        {section.name}
                                                    </option>
                                                )
                                            })
                                        }
                                </select>
                                <p style={{fontSize: 12, color: '#828282'}}>
                                Las categorías, a diferencia de las etiquetas, pueden tener una jerarquía. Puedes tener una categoría de Jazz y, debajo de ella, categorías secundarias.
                                </p>
                            </div>
                        </IonCol>
                        <IonCol>
                            <div style={{padding: 15}}>
                                <p style={{fontSize: 14, color: '#333333', fontFamily: 'Poppins'}}>Descripción</p>
                                <textarea
                                    name={'description'}
                                    onChange={editSection}
                                    value={section.description}
                                    style={{
                                        border: '1px solid #c4c4c4',
                                        fontSize: 12,
                                        borderRadius: 5,
                                        height: 114.22,
                                        padding: 10,
                                        width: '100%'}}
                                />
                                <p style={{fontSize: 12, color: '#828282'}}>
                                La descripción no es destacada de forma predeterminada; sin embargo, algunos temas pueden mostrarla.
                                </p>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'right', paddingRight: 30}}>
                    <button onClick={handleClose} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 10, borderRadius: 10, backgroundColor: '#D4D5D9', color: '#fff'}}>
                        Salir
                    </button>
                    <button onClick={() => {sectionIn ? changeCategory() : createCategory()}} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#98b1eb', color: '#fff'}}>
                        {`${sectionIn ? 'Editar' : 'Añadir'}`} categoría 
                    </button>
                </div>
            </IonContent>
        </IonModal>
    )
}