import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonItem, IonRow, IonText } from "@ionic/react"
import { Admin, AiHumans, Dashboard, Documents, Eye, Files, Gear, Home, ImKChatLogo, KauelLogoTransparent, Logout, Menu, Pro, Specialities, User } from "../../icons"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/Auth.context"
import SelectFileModal from "../../modals/SelectFile.modal"
import AvatarOptionsModal from "../../modals/AvatarOptions.modal"
import { useHistory, useLocation } from "react-router"
import { createOutline } from "ionicons/icons"
import FileManagementModal from "../../modals/FileManagement.modal"
import SpecialitiesManagementModal from "../../modals/Specialities.modal"
import UsersManagementModal from "../../modals/Users.modal"
import { ProfileModal } from "../../modals/Profile.modal"

const navBarButtonsInit: {
    id: number,
    name: string,
    disabled: boolean,
    icon: string,
    url: string,
    state: boolean,
    external: boolean
    admin: boolean
}[] = [
    /* {
        id: 0,
        name: 'Inicio',
        disabled: false,
        icon: Dashboard,
        url: '/home',
        state: true,
        external: false,
        admin: false
    }, */
    {
        id: 1,
        name: 'Especialidades',
        disabled: false,
        icon: Specialities,
        url: '/specialities',
        state: true,
        external: false,
        admin: true
    },
    {
        id: 2,
        name: 'Documentos',
        disabled: false,
        icon: Files,
        url: '/files',
        state: true,
        external: false,
        admin: true
    },
    {
        id: 3,
        name: 'Administración Usuarios',
        disabled: false,
        icon: Admin,
        url: '/user-admin',
        state: true,
        external: false,
        admin: true
    }
]

const navBarButtonsBottom: {
    id: number,
    name: string,
    disabled: boolean,
    icon: string,
    url: string,
    state: boolean,
    external: boolean
}[] = [
    /* {
        id: 0,
        name: 'Pro version',
        disabled: false,
        icon: Pro,
        url: 'https://ai-labhelper.com/demo-pro/',
        state: true,
        external: true
    },
    {
        id: 1,
        name: 'Vista usuario',
        disabled: true,
        icon: Eye,
        url: '/specialities',
        state: false,
        external: false
    },
    {
        id: 2,
        name: 'Cuenta',
        disabled: true,
        icon: Gear,
        url: '/files',
        state: false,
        external: false
    } */
]


export const NavbarContainer = () => {
    const {logout, isSmartPhone, logo, iconPage, userInvited, styleCompany, user} = useAuthContext()
    const [navBarExpanded, setNavBarExpanded] = useState(false)
    const [enableText, setEnableText] = useState(false)
    const [openAvatarOptionModal, setOpenAvatarOptionModal] = useState(false)

    const [openFilesManagerModal, setOpenFilesManagerModal] = useState(false)
    const [openSpecialitiesManagerModal, setOpenSpecialitiesManagerModal] = useState(false)
    const [openUsersManagerModal, setOpenUsersManagerModal] = useState(false)
    const [openProfileModal, setOpenProfileModal] = useState(false)

    const [navBarButtons, setNavBarButtons] = useState<{
        id: number,
        name: string,
        disabled: boolean,
        icon: string,
        url: string,
        state: boolean
        admin: boolean
    }[]>(navBarButtonsInit)
    /* const [openSelectAvatarModal, setOpenSelectAvatarModal] = useState(false) */

    const history = useHistory()
    const location = useLocation()

    /* useEffect(() => {
        setNavBarButtons(navBarButtonsInit)
    }, []) */

    useEffect(() => {
        console.log(location.pathname)
        const navBarListCache: {
            id: number,
            name: string,
            disabled: boolean,
            icon: string,
            url: string, 
            state: boolean,
            admin: boolean
        }[] = [...navBarButtons]
        /* navBarListCache.forEach((button) => {
            if (button.url === location.pathname) {
                button.state = true
            } else {
                button.state = false
            }
        }) */
        console.log(navBarListCache)
        setNavBarButtons(navBarListCache)
    }, [location.pathname])

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            const navBar = document.getElementById('navBar')
            if (navBar && navBar.contains(e.target)){
              // Clicked in box
            } else{
              if (navBarExpanded) {
                expandMenu()
              }
            }
        });
    })

    useEffect(() => {
        const navBar = document.getElementById('navBar')
        if (navBarExpanded) {
            if (navBar) {
                navBar.style.width = '299px'
                navBar.style.transition = '1s'
                setTimeout(() => {
                    setEnableText(true)
                }, 600);
            }    
        } else {
            if (navBar) {
                setTimeout(() => {
                    setEnableText(false)
                }, 100);
                navBar.style.width = '108px'
                navBar.style.transition = '1s'
            } 
        }
    }, [navBarExpanded])

    useEffect(() => {
        if (isSmartPhone) {
            setEnableText(true)
        }
    }, [isSmartPhone])

    const expandMenu = () => {
        setNavBarExpanded(navBarExpanded ? false : true)
    }

    return (
        <div className="navBar-container" id={'navBar-container'}>

            {
                openProfileModal && <ProfileModal
                    open={openProfileModal}
                    handleClose={() => {setOpenProfileModal(false)}}
                />
            }
            
            {openAvatarOptionModal && <AvatarOptionsModal
                open={openAvatarOptionModal}
                handleClose={() => {setOpenAvatarOptionModal(false)}}
            />}
            {
                openFilesManagerModal && <FileManagementModal
                    open={openFilesManagerModal}
                    handleClose={() => {setOpenFilesManagerModal(false)}}
                />
            }
            {
                openSpecialitiesManagerModal && <SpecialitiesManagementModal
                    open={openSpecialitiesManagerModal}
                    handleClose={() => {setOpenSpecialitiesManagerModal(false)}}
                />
            }
            {
                openUsersManagerModal && <UsersManagementModal
                    open={openUsersManagerModal}
                    handleClose={() => {setOpenUsersManagerModal(false)}}
                />
            }
            <button style={{backgroundColor: 'transparent'}} onClick={() => {expandMenu()}}>
                <IonGrid style={{padding: 0, height: 85}}>
                    <IonRow style={{padding: 0}}>
                        <div style={{textAlign: 'left', maxWidth: 140}}>
                            {
                                !enableText && <IonCol style={{padding: 0}} /* size={enableText ? 'auto' : '12'} */>
                                    <img width={'100%'} style={{maxWidth: 140}} src={iconPage}/>
                                </IonCol>
                            }
                            {
                                enableText && 
                                <IonCol style={{padding: 0}} size="auto">
                                    <img /* width={'60%'} */ style={{maxWidth: 140}} src={logo} alt="" />
                                </IonCol>
                            }
                        </div>
                    </IonRow>
                </IonGrid>
            </button>
            {(user?.roles[0].name === 'admin' || user?.roles[0].name === 'superAdmin') && <div style={{marginTop: 30, marginBottom: 30, textAlign: 'center', maxWidth: 48}}>
                <p style={{fontFamily:'Manrope', color: '#64748B'}}>Menu</p>
            </div>}
            {userInvited && <IonButton fill="clear" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {alert('Demo no permite creación de cuentas.')}}>
                <IonIcon icon={createOutline} style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} /> {enableText && <IonText style={{marginRight: 10}}>Create account</IonText>}
            </IonButton>}
            {!userInvited && <div>
                {
                    navBarButtons.map((button) => {
                        if (button.admin) {
                            if (user?.roles[0].name === 'doctor') {
                                null
                            } else {
                                return (
                                    <div key={button.id}>
                                        <IonButton disabled={button.disabled} style={{'--background-hover':'var(--k-eternal-button-second)', '--background-focused': 'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} 
                                            onClick={() => {
                                                button.url === '/files' ?
                                                setOpenFilesManagerModal(true) :
                                                button.url === '/specialities' ?
                                                setOpenSpecialitiesManagerModal(true) :
                                                button.url === '/user-admin' ?
                                                setOpenUsersManagerModal(true) :
                                                history.push(`${button.url}`)
                                            }}
                                            >
                                            <img
                                                height={24}
                                                width={24} 
                                                src={button.icon}
                                                style={
                                                    {
                                                        filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)',
                                                        marginRight: 10
                                                    }
                                                } /> {
                                                    enableText && 
                                                    <IonText style={{marginRight: 10, filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)'}}>{button.name}</IonText>}
                                        </IonButton>
                                        <br />
                                    </div>
                                )
                            }
                        } else {
                            return (
                                <div key={button.id}>
                                    <IonButton disabled={button.disabled} style={{'--background-hover':'var(--k-eternal-button-second)', '--background-focused': 'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} 
                                        onClick={() => {
                                            button.url === '/files' ?
                                            setOpenFilesManagerModal(true) :
                                            button.url === '/specialities' ?
                                            setOpenSpecialitiesManagerModal(true) :
                                            button.url === '/user-admin' ?
                                            setOpenUsersManagerModal(true) :
                                            history.push(`${button.url}`)
                                        }}
                                        >
                                        <img
                                            height={24}
                                            width={24} 
                                            src={button.icon}
                                            style={
                                                {
                                                    filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)',
                                                    marginRight: 10
                                                }
                                            } /> {
                                                enableText && 
                                                <IonText style={{marginRight: 10, filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)'}}>{button.name}</IonText>}
                                    </IonButton>
                                    <br />
                                </div>
                            )
                        }
                    })
                }
            </div>}
            <div className="navBar-container" style={{position: "absolute", left: 0, bottom: 0, textAlign: 'left'}}>
                {
                    navBarButtonsBottom.map((button) => {
                        return (
                            <div key={button.id}>
                                <IonButton disabled={button.disabled} style={{'--background-hover':'var(--k-eternal-button-second)', '--background-focused': 'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {button.external ? window.open(button.url, '_blank') : history.push(`${button.url}`)}}>
                                    <img
                                        height={24}
                                        width={24} 
                                        src={button.icon}
                                        style={
                                            {
                                                filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)',
                                                marginRight: 10
                                            }
                                        } /> {
                                            enableText && 
                                            <IonText style={{marginRight: 10, filter: button.state ? 'brightness(0) saturate(100%) invert(52%) sepia(11%) saturate(1484%) hue-rotate(186deg) brightness(81%) contrast(91%)' : 'brightness(0) saturate(100%) invert(80%) sepia(16%) saturate(672%) hue-rotate(192deg) brightness(86%) contrast(89%)'}}>{button.name}</IonText>}
                                </IonButton>
                                <br />
                            </div>
                        )}
                    )
                }
                <p style={{fontSize: 14, color: '#64748B', fontFamily: 'Poppins'}}>Profile</p>
                <div style={{width: '100%', height: 40, fontFamily: 'Poppins'}}>
                    <button
                        style={{
                            backgroundColor: 'transparent'
                        }}
                        onClick={() => {
                        setOpenProfileModal(true)
                    }}>
                        <IonGrid>
                            <IonRow>
                                <IonCol style={{maxWidth: 40}}>
                                    <img src={user?.image ? user.image : `/assets/images/no_profile.webp`} style={{borderRadius: '50%'}} alt="" />
                                </IonCol>
                                {enableText && <IonCol>
                                    <div style={{paddingLeft: 10}}>
                                        <p style={{fontSize: 10, color: '#191D23', marginTop: 5}}>{user?.name} {user?.lastName}</p>
                                        <p style={{fontSize: 10, color: '#A0ABBB', marginTop: 5}}>{user?.email}</p>
                                    </div>
                                </IonCol>}
                            </IonRow>
                        </IonGrid>
                    </button>
                </div>
                <div style={{width: '100%', textAlign: 'center', marginTop: 10}}>
                    <IonButton style={{'--background-hover':'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {logout()}}>
                        <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Logout} /> {enableText && <IonText style={{marginRight: 10}}>Log out</IonText>}
                    </IonButton>
                </div>
            </div>
        </div>
    )
}