import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRippleEffect, IonRow, IonToolbar } from '@ionic/react'
import './modals.css'
import { useFilesContext } from '../context/Files.context'
import { useEffect, useState } from 'react'
import { FilesData } from '../interfaces/Files.interface'
import { chevronForward, close, cloudUploadOutline } from 'ionicons/icons'
import { useAuthContext } from '../context/Auth.context'
import { uploadFile } from '../routes/files.routes'
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { UploadIcon } from '../icons'
import SelectFileModal from './SelectFile.modal'
import { Pagination } from '@mui/material'

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  

const FileManagementModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    const {userId} = useAuthContext()
    const {files, getCountFilesByNumberGroup, getFilesFromDatabase} = useFilesContext()
    const [filesLoad, setFilesLoad] = useState<any[]>([])
    const [speciality, setSpeciality] = useState<string>()
    const [openSelectFileModal, setOpenSelectFileModal] = useState(false)

    const [totalPages, setTotalPages] = useState(0)

    const [page, setPage] = useState(1)

    const [groupPage, setGroupPage] = useState(10)

    useEffect(() => {
        getNumberTotalFiles()
    }, [])

    const getNumberTotalFiles = async () => {
        const num = await getCountFilesByNumberGroup(groupPage)
        console.log(Math.floor(num))
        setTotalPages(Math.floor(num))
    }

    useEffect(() => {
        console.log(totalPages)
        if (totalPages > 0) {
            findFiles(page)
        }
    }, [totalPages, page])

    useEffect(() => {
        console.log(filesLoad)
    }, [filesLoad])

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }

    const findFiles = async (value: number) => {
        const start = value * groupPage
        const end = groupPage
        const response = await getFilesFromDatabase(start, end)
        setFilesLoad(response.files)
    }

    /* const selectFile = (file: FilesData) => {
        setFileSelected(file)
        handleClose()
    } */

    const getFile = () => {
        const input = document.getElementById('file-uploader')
        if (input) {
            input.click()
        }
    }

    const progress = (e: any, nameFile: string, idFile: number) => {
        console.log(e.progress * 100)
        console.log(e.loaded === e.total)
        console.log((e.loaded === e.total) ? true : false)
        /* const isLoaded = (e.loaded === e.total) ? true : false */
        /* const filesLoadTemp = [...filesLoad]
        const fileIndex = filesLoadTemp.findIndex((file) => {
            (file.name === nameFile && idFile === file.id)
        })
        console.log(fileIndex)
        if (e.progress ) {
            filesLoadTemp[fileIndex].progress = (e.progress * 100)
            setFilesLoad(filesLoadTemp)
        }

        if (isLoaded) {
            const filesLoadCache = filesLoad.filter(file => {
                return (idFile !== file.id)
            })
            setFilesLoad(filesLoadCache)
        } */
        
    }

    /* const getFileToSendServer = async (e: any) => {
        console.log(e.target.files[0])
        const file = e.target.files[0]
        const filesLoadCache: any[] = [...filesLoad]
        const newFile = {
            name: e.target.files[0].name,
            id: Date.now()
        }
        filesLoadCache.push(newFile)
        setFilesLoad(filesLoadCache)
        if (speciality) {
            const response = await uploadFile(file, userId, Date.now(), progress, speciality)
            console.log(response)
        }
        getFiles()
    } */



    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={false}
            className='file-management-modal'
        >
            {
                openSelectFileModal && <SelectFileModal
                    open={openSelectFileModal}
                    handleClose={() => {setOpenSelectFileModal(false)}}
                />
            }
            <IonContent class='ion-padding' className='modalContainer' style={{position: 'relative'}}>
                <IonToolbar>
                    {<div style={{paddingLeft: 15}}>
                        <h3 style={{fontFamily: 'Poppins'}}>Documentos cargados{/* Gestión de Documentos */}</h3>
                        
                    </div>}
                    <IonButtons slot='end'>
                        {/* <IonButton onClick={() => {getFile()}}>
                            <IonIcon icon={cloudUploadOutline} />
                        </IonButton> */}
                        <IonButton onClick={() => {handleClose()}}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <div style={{width: '100%', textAlign: 'left', padding: 20, fontFamily: 'Poppins'}}>
                    <IonGrid>
                        <IonRow style={{paddingRight: 25, borderBottom: '1px #ccc solid'}}>
                            {/* <IonCol size='5'>
                                <p>Alias</p>
                            </IonCol> */}
                            <IonCol size='8'>
                                <p>Nombre Documento</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Categorías</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Acciones</p>
                            </IonCol>
                        </IonRow>
                        <div style={{width: '100%', height: '30vh', overflowY: 'auto', color: '#828282'}}>
                            {
                                filesLoad.map((file, index) => {
                                    return (
                                        <IonRow key={index}>
                                            {/* <IonCol size='5'>
                                                <p></p>
                                            </IonCol> */}
                                            <IonCol size='8'>
                                                <p>{file.name}</p>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <p>{file.idsection.map((section: any, i: number) => {
                                                    return `${section.name}${i === (file.idsection.length - 1) ? '' : ',\n'}`
                                                })}</p>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <p>Sin acciones</p>
                                            </IonCol>
                                        </IonRow>
                                    )
                                })
                            }
                        </div>
                    </IonGrid>
                </div>
                <div style={{position: 'absolute', right: 10, bottom: 80}}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={changePage}
                        /* onPageChange={handleChangePage} */
                        /* rowsPerPage={rowsPerPage} */
                        /* onRowsPerPageChange={handleChangeRowsPerPage} */
                        
                        />
                </div>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'right', paddingRight: 30}}>
                    <button onClick={handleClose} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 10, borderRadius: 10, backgroundColor: '#D4D5D9', color: '#fff'}}>
                        Salir
                    </button>
                    {/* <button onClick={() => {setOpenSelectFileModal(true)}} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#41507A', color: '#fff'}}>
                        Subir Documento
                    </button> */}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default FileManagementModal
