import axios from 'axios'

const url = window.location.host.split('.')[0].includes('qa')  ? process.env.REACT_APP_SERVER_URL_QA : window.location.host.split('.')[0] === 'app' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST

const getAllRoles = async () => {
    const response = await axios.get(url+'/api/roles/getRoles')
    return response.data
}

const getRolesByCompany = async (companyId: any) => {
    const response = await axios.get(`${url}/api/roles/getRolesById/organization/${companyId._id}`)
    return response.data
}

export default {
    getAllRoles,
    getRolesByCompany
}