import axios, { AxiosRequestConfig } from "axios"

const url = window.location.host.split('.')[0].includes('qa')  ? process.env.REACT_APP_SERVER_URL_QA : window.location.host.split('.')[0] === 'app' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST

export const getFilesByUserId = async (userId: string) => {
    const response = await axios.post(url+'/api/files/getFilesByUserId', {userId: userId})
    return response.data
}

export const getFilesByCompany = async (companyId: string) => {
    const response = await axios.post(url+'/api/files/getFilesByCompany', {companyId})
    return response.data
}

export const getFileByFileID = async (id: string) => {
    const response = await axios.get(url+`/api/files/getFileByFileID/${id}`)
    return response.data
}

export const getFiles = async (start: number, end: number) => {
    const response = await axios.get(url+`/api/files/getFiles?start=${start}&end=${end}`)
    return response.data
}

export const countFiles = async () => {
    const response = await axios.get(url+`/api/files/countFiles`)
    return response.data
}

export const updateFileById = async (fileData: any, userId: string) => {
    const config = {
        headers: { 
            'Authorization': userId
        },
    }
    const response = await axios.post(url+'/api/files/updateFileById', {fileData: fileData}, config)
    return response.data
}

export const deleteFile = async (fileData: any, userId: string) => {
    try {
        const config = {
            headers: { 
                'Authorization': userId
            },
        }
        const response = await axios.post(url+'/api/files/deleteFile', {fileData: fileData}, config)
        return response.data
    } catch (error) {
        return error
    }
}

export const uploadFile = async (file: File, userId: string, idFile: number, progress: any, section: string) => {
    const formData = new FormData()
    formData.append('files', file, file.name)
    formData.append('idFile', idFile.toString())
    formData.append('fileName', file.name)
    formData.append('section', section)
    const config: AxiosRequestConfig = {
        headers: { 
            'Content-Type': 'multipart/form-data',
            'Authorization': userId
        },
        onUploadProgress: progressEvent => progress(progressEvent, file.name, idFile)
    }
    const response = await axios.post(url+'/api/files/uploadFile', formData, config)
    return response.data
}