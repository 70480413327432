import { IonButton, IonButtons, IonCard, IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonRippleEffect, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonToolbar } from '@ionic/react'
import './modals.css'
import { useFilesContext } from '../context/Files.context'
import { useEffect, useRef, useState } from 'react'
import { FilesData } from '../interfaces/Files.interface'
import { chevronForward, close, cloudUploadOutline } from 'ionicons/icons'
import { useAuthContext } from '../context/Auth.context'
import { uploadFile } from '../routes/files.routes'
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CloudUpload, Trash, UploadIcon } from '../icons'
import { LinearProgress } from '@mui/material'
import { useSectionContext } from '../context/Section.context'

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  

const SelectFileModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    const {user} = useAuthContext()
    const {files, setFileSelected, /* getFiles */} = useFilesContext()
    const {sections} = useSectionContext()
    const [filesLoaded, setFilesLoaded] = useState<string[]>([])
    const [categoryList, setCategoryList] = useState<any[]>([])
    const [filesToLoad, setFilesToLoad] = useState<any[]>([])
    const [filesUploaded, setFilesUploaded] = useState(0)
    const [fileInProgress, setFileInProgress] = useState('')
    const [section, setSection] = useState<string>('')
    const [progressUpload, setProgressUpload] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [categoryToFind, setCategoryToFind] = useState('')
    const popover = useRef<HTMLIonPopoverElement>(null);

    const [openCategoryList, setOpenCategoryList] = useState(false)

    const [sectionList, setSectionList] = useState<any[]>([])
    const [sectionListCache, setSectionListCache] = useState<any[]>([])

    const [selectAll, setSelectAll] = useState(false)

    const [tag, searchTag] = useState('')

    useEffect(() => {
        console.log(filesLoaded)
    }, [filesLoaded])

    useEffect(() => {
        console.log(tag)
        if (tag.length > 2) {
            const sectionsCache = [...sectionListCache].filter(s => s.name.toLowerCase().includes(tag.toLowerCase()))
            setSectionList(sectionsCache)
        } else {
            setSectionList(sectionListCache)
        }
    }, [tag])

    useEffect(() => {
        if (categoryToFind.length > 2) {
            const sectionsFiltered = sections.filter((section) => section.name.toLowerCase().includes(categoryToFind.toLowerCase())).map((s) => {
                s.checked = selectAll;
                return s
            })
            setSectionList(sectionsFiltered)
            setSectionListCache(sectionsFiltered)
        } else {
            setSectionList(sections.map((s) => {
                s.checked = selectAll;
                return s
            }))
            setSectionListCache(sections.map((s) => {
                s.checked = selectAll;
                return s
            }))
        }
    }, [categoryToFind, selectAll])

    const selectFile = (file: FilesData) => {
        setFileSelected(file)
        handleClose()
    }

    const getFile = () => {
        const input = document.getElementById('file-uploader')
        if (input) {
            input.click()
        }
    }

    const progress = (e: any, nameFile: string, idFile: number) => {
        console.log(e.progress * 100)
        setProgressUpload(e.progress * 100)
        console.log(e.loaded === e.total)
        console.log((e.loaded === e.total) ? true : false)
        
    }

    const getFilesToSendServer = async (e: any) => {
        setUploading(false)
        console.log(e.target.files)
        const files : any[] = Array.from(e.target.files)
        setFilesToLoad(files)
        setFilesLoaded([])
        setFileInProgress('')
    }

    const uploadFiles = () => {
        if (filesToLoad.length > 0) {
            /* setUploading(true) */
            console.log(files)
            const fileList: string[] = []
            /* sendFileOneByOne(filesToLoad, 0, fileList) */
        } else {
            alert('No hay dcumentos seleccionados.')
        }
    }

    const sendFileOneByOne = async (files: any[], index: number, fileList: string[]) => {
        if (!files[index]) {
            alert('Archivos cargados')
        } else {
            setFilesUploaded(index + 1)
            const file = files[index]
            setFileInProgress(file.name)
            await uploadFile(file, user!._id, Date.now(), progress, section)
            setProgressUpload(100)
            setTimeout(() => {
                fileList.push(file.name)
                setFilesLoaded(fileList)
                setProgressUpload(0)
                setTimeout(() => {
                    sendFileOneByOne(files, index + 1, fileList)
                }, 1000)
            }, 1000);
        }
    }

    const categoryListToSelect = (e: any) => {
        console.log(e)
        popover.current!.event = e;
        setOpenCategoryList(true)
    }

    const selectSection = (e: any) => {
        const checked = e.target.checked
        const label = e.target.innerText

        const newSectionList = [...sectionListCache]
        newSectionList.forEach((s) => {
            if (s.name === label) {
                s.checked = checked
            }
        })

        const numberToAll = [...newSectionList].filter(x => x.checked).length

        if (numberToAll === newSectionList.length) {
            setSelectAll(true)
        }

        console.log('Number: ', numberToAll)

        setSectionList(newSectionList)
        setSectionListCache(newSectionList)
    }

    const uploadFileToAIServer = () => {
        /* uploadFileDirect(files, [...sectionList].filter(x => x.checked).map(s => {
            return s.name
        })) */
    }

    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={true}
            showBackdrop={true}
            className='file-selected-modal'
        >
            <IonToolbar>
                <IonButtons slot='end'>
                    <IonButton onClick={() => {handleClose()}}>
                        <IonIcon icon={close} />
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <IonContent className='modalContainer' style={{position: 'relative'}}>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold', fontSize: 22}}>
                        Cargar documento 
                    </p>
                    <div 
                        onClick={() => {
                            const input = document.getElementById('uploadFile')
                            if (input) {
                                input.click()
                            }
                        }}
                        style={{margin: '0px 47.5px', padding: '30px 10px 10px 10px', backgroundColor: '#F8F8FF', borderRadius: 10, cursor: 'pointer'}}>
                        <img src={CloudUpload} height={54}/>
                        <p style={{fontWeight: 'bold', fontSize: 14}}>
                            {/* Arrastre y suelte documentos o */} <b style={{color: '#0A7ABF'}}>Busque documentos</b>
                        </p>
                    </div>
                    <input multiple onChange={getFilesToSendServer} id='uploadFile' type="file" accept='application/pdf' style={{display: 'none'}}/>
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: '0px 30px'}}>
                    {
                        !uploading
                        ?
                        <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>{filesToLoad.length > 0 ? `${filesToLoad.length} archivos seleccionados.` : 'Seleccione archivos para cargar'}</p>
                        :
                        <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>Subiendo - {filesUploaded} / {filesToLoad.length} archivos</p>
                    }
                    <p>{fileInProgress}</p>
                    <IonGrid>
                        <IonRow>
                            <IonCol size='11'>
                                <LinearProgress variant="determinate" value={progressUpload} />
                            </IonCol>
                            <IonCol size='1'>
                                <p style={{fontSize: 10, margin: 0, marginLeft: 10, color: '#ccc'}}>{Math.ceil(progressUpload)}%</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: '0px 30px'}}>
                    <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>Cargados: </p>
                </div>
                <div style={{width: '100%', maxHeight: '10%', overflowY: 'auto', padding: '0px 30px'}}>
                    {
                        filesLoaded.map((fileName, index) => {
                            return (
                                <div key={index} style={{marginTop: 10, maxHeight: 36, border: '1px solid #11AF22', width: '100%', position: 'relative', padding: 5, marginBottom: 10}}>
                                    <p style={{fontWeight: 'bold', fontSize: 11, fontFamily: 'Poppins', margin: 0}}>{index + 1}.- {fileName}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: '0px 30px'}}>
                    <p style={{fontWeight: 'bold', fontSize: 14, fontFamily: 'Poppins'}}>Categoría: </p>
                    <button className={'selectCategories'} onClick={(e) => {categoryListToSelect(e)}}>
                        <IonGrid>
                            <IonRow>
                            {
                                sectionList.map((cat) => {
                                    if (cat.checked) {
                                        return (
                                            <IonCol size='auto' key={`${cat._id}`} style={{padding: '0px 10px', marginBottom: 10}}>
                                                <div style={
                                                    {
                                                        textAlign: 'left',
                                                        border: '1px #F0F0F0 solid',
                                                        borderRadius: 3, 
                                                        height: 26, 
                                                        backgroundColor: '#F0F0F0',
                                                        padding: '5px 10px'
                                                    }
                                                    }>
                                                    <p style={{margin: 1, fontWeight: 'bold'}}>{cat.name}</p>
                                                </div>
                                            </IonCol>
                                        )
                                    } else {
                                        return null
                                    }
                                })
                            }
                            </IonRow>
                        </IonGrid>
                    </button>
                    <IonPopover side="top" alignment="center" ref={popover} isOpen={openCategoryList} onDidDismiss={() => setOpenCategoryList(false)}>
                        <IonItem>
                            <IonSearchbar value={tag} onIonInput={(e) => {searchTag(e.target.value as string)}} />
                        </IonItem>
                        <IonItem button>
                            <IonCheckbox className={'selectCategoriesCheckbox'} onIonChange={() => {setSelectAll(selectAll ? false : true)}} checked={selectAll} slot="start" labelPlacement="end" >
                            Todo
                            </IonCheckbox>
                        </IonItem>
                        <div style={{height: 250, width: '100%', overflowY: 'auto'}}>
                        
                        {
                            sectionList.map((s) => {
                                return (
                                    <IonItem key={s._id} button>
                                        <IonCheckbox className={'selectCategoriesCheckbox'} onIonChange={selectSection} checked={s.checked} slot="start" labelPlacement="end" >
                                        {s.name}
                                        </IonCheckbox>
                                    </IonItem>
                                )
                            })
                        }
                        </div>
                    </IonPopover>
                </div>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'center'}}>
                    <button disabled={filesToLoad.length === 0} onClick={uploadFiles} className="ion-activatable ripple-parent rectangle" style={{width: 200, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#41507A', color: '#fff'}}>
                        Cargar
                        <IonRippleEffect></IonRippleEffect>
                    </button>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default SelectFileModal
