import axios from "axios"

const url = window.location.host.split('.')[0].includes('qa')  ? process.env.REACT_APP_SERVER_URL_QA : window.location.host.split('.')[0] === 'app' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST

export const getSections = async () => {
    const response = await axios.get(url+'/api/section/getSections')
    return response.data
}

export const addSection = async (section: any) => {
    const response = await axios.post(url+'/api/section', {section})
    return response.data
}

export const editSection = async (section: any) => {
    const response = await axios.put(url+'/api/section', {section})
    return response.data
}

export const removeSection = async (section: any) => {
    const response = await axios.delete(url+`/api/section/deleteSection/${section._id}`)
    return response.data
}