import { IonButton, IonContent, IonModal } from '@ionic/react'
import { Close, Error, GreenCheck } from '../icons'
import './modals.css'

const ConfirmModal = ({open, handleClose, message, confirmButton}:{open: boolean, handleClose: () => void, message: string, confirmButton: (res: any) => void}) => {
    console.log(message)
    return (
        <IonModal
            isOpen={open}
            onWillDismiss={handleClose}
            className='confirm'
            backdropDismiss={false}
            showBackdrop={true}
        >
            
            <IonContent class='ion-padding'>
                <div style={{width: '100%', textAlign: 'center', position: 'relative', height: '100%', padding: 30}}>
                    <p>
                        {message}
                    </p>
                    <div style={{textAlign: 'center', position: 'absolute', bottom: 10, width: '100%', left: 0}}>
                        <IonButton onClick={() => {confirmButton('ok'); handleClose()}}>
                            Continuar
                        </IonButton>
                    </div>
                </div>
                <div className='closeButtonModal' onClick={handleClose}> 
                    <img src={Close} alt='close-button' width={24} height={24} />
                </div>
            </IonContent>
        </IonModal>
    )
}

export default ConfirmModal