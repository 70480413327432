import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonSkeletonText, IonToolbar } from '@ionic/react'
import './modals.css'
import { useEffect, useState } from 'react'
import { close } from 'ionicons/icons'
import { useAuthContext } from '../context/Auth.context'
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Edit, Trash } from '../icons'
import { useUsersContext } from '../context/Users.context'
import { Pagination } from '@mui/material'
import { UserProfileModal } from './User.profile.modal'

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  

const UsersManagementModal = ({open, handleClose}:{open: boolean, handleClose: () => void}) => {
    const {userId} = useAuthContext()
    const {users, getUser, deleteUser, readAllUsers} = useUsersContext()

    const [usersGroup, setUsersGroup] = useState<any>()

    const [usersPerPage, setUsersPerPage] = useState(10)

    const [pageSelected, setPageSelected] = useState(1)

    const [usersCitiesGroup, setUsersCitiesGroup] = useState<any>()
    const [usersRolesGroup, setUsersRolesGroup] = useState<any>()

    const [usersToShow, setUsersToShow] = useState<any[]>([])
    const [usersToShowCache, setUsersToShowCache] = useState<any[]>([])

    const [openUserProfile, setOpenUserProfile] = useState(false)
    const [nameUser, setNameUser] = useState('')
    const [emailUser, setEmailUser] = useState('')

    useEffect(() => {
        readAllUsers()
    }, [])

    useEffect(() => {
        if (users.length > 0) {
            setUsersToShow(users.sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                return 0
            }))
            setUsersToShowCache(users.sort((a, b) => {
                if (a.name > b.name) {
                    return 1
                }
                if (a.name < b.name) {
                    return -1
                }
                return 0
            }))
            const usersCitiesGroupCache: any = {}
            const usersRolesGroupCache: any = {}

            users.forEach((user) => {
                if (!usersCitiesGroupCache[user.city]) {
                    usersCitiesGroupCache[user.city] = []
                }
                usersCitiesGroupCache[user.city].push(user)
                if (user.roles && user.roles[0] && user.roles[0].name) {
                    if (!usersRolesGroupCache[user.roles[0].name]) {
                        usersRolesGroupCache[user.roles[0].name] = []
                    }
                    usersRolesGroupCache[user.roles[0].name].push(user)
                } else {
                    if (!usersRolesGroupCache['SIN INFORMACIÓN']) {
                        usersRolesGroupCache['SIN INFORMACIÓN'] = []
                    }
                    usersRolesGroupCache['SIN INFORMACIÓN'].push(user)
                }
            })
            setUsersRolesGroup(usersRolesGroupCache)
            setUsersCitiesGroup(usersCitiesGroupCache)
        }
    }, [users])

    useEffect(() => {
        if (usersToShow.length > 0) {
            const usersGroupCache: any = {}
            let page = 0
            usersToShow.forEach((user, index) => {
                if (!usersGroupCache[page]) {
                    usersGroupCache[page] = []
                }
                usersGroupCache[page].push(user)
                if ((index + 1) === ((page + 1) * usersPerPage)) {
                    page = page + 1
                }
            })
            setUsersGroup(usersGroupCache)
        } else {
            const usersGroupCache: any = {}
            const page = 0
            usersGroupCache[page] = []
            setUsersGroup(usersGroupCache)
        }
    }, [usersToShow])

    useEffect(() => {
        if (nameUser.length > 2) {
            const usersToShowTemp = usersToShowCache.filter((user) => {
                if (`${user.name} ${user.lastName}`.toLowerCase().match(nameUser.toLowerCase())) {
                    return user
                }
            })
            setUsersToShow(usersToShowTemp)
        } else {
            if (usersToShowCache && usersToShowCache.length > 0) {
                setUsersToShow(usersToShowCache)
            }
        }
    }, [nameUser])
    
    useEffect(() => {
        if (emailUser.length > 2) {
            const usersToShowTemp = usersToShowCache.filter((user) => {
                if (`${user.email}`.toLowerCase().includes(emailUser.toLowerCase())) {
                    return user
                }
            })
            setUsersToShow(usersToShowTemp)
        } else {
            if (usersToShowCache && usersToShowCache.length > 0) {
                setUsersToShow(usersToShowCache)
            }
        }
    }, [emailUser])

    const findByName = (e: any) => {
        setNameUser(e.target.value)
        setPageSelected(1)
    }

    const findByEmail = (e: any) => {
        setEmailUser(e.target.value)
        setPageSelected(1)
    }

    return (
        <IonModal
            isOpen={open}
            onWillDismiss={() => {handleClose()}}
            backdropDismiss={false}
            className='users-management-modal'
        >
            {
                openUserProfile &&
                <UserProfileModal
                    open={openUserProfile}
                    handleClose={() => {setOpenUserProfile(false)}}
                />
            }
            <IonContent class='ion-padding' className='modalContainer' style={{position: 'relative'}}>
                <IonToolbar style={{borderBottom: '3px solid #5E6F9D'}}>
                    {<div style={{paddingLeft: 15}}>
                        <h3 style={{fontFamily: 'Poppins'}}>Usuarios</h3>
                        
                    </div>}
                    <IonButtons slot='end'>
                        <IonButton onClick={() => {handleClose()}}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <div style={{width: '100%', textAlign: 'right', padding: 20}}>
                    {usersRolesGroup && <input
                        onChange={findByEmail}
                        value={emailUser}
                        placeholder='EMAIL USUARIO' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}
                    />}
                    {usersRolesGroup && <input
                        onChange={findByName}
                        value={nameUser}
                        placeholder='NOMBRE USUARIO' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}
                    />}
                    {usersCitiesGroup && 
                    <select
                        onChange={(e) => {setPageSelected(1); setTimeout(() => {
                            setUsersToShow(e.target.value === 'all' ? users : usersCitiesGroup[e.target.value])
                            setUsersToShowCache(e.target.value === 'all' ? users : usersCitiesGroup[e.target.value])
                        }, 500);}}
                        placeholder='Ciudad' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}>
                        <option value={'all'}>
                            CIUDADES
                        </option>
                        {
                            Object.keys(usersCitiesGroup).sort((a, b) => {
                                if (a < b) {
                                    return -1
                                }
                                if (a > b) {
                                    return 1
                                }
                                return 0
                            }).map((key, index) => {
                                return(
                                    <option key={index} value={key}>
                                        {key === 'undefined' ? 'SIN INFORMACIÓN' : key.toUpperCase()}
                                    </option>
                                )
                            })
                        }
                    </select>}
                    {usersRolesGroup && 
                    <select
                        onChange={(e) => {setPageSelected(1); setTimeout(() => {
                            setUsersToShow(e.target.value === 'all' ? users : usersRolesGroup[e.target.value])
                            setUsersToShowCache(e.target.value === 'all' ? users : usersCitiesGroup[e.target.value])
                        }, 500);}}
                        placeholder='Ciudad' style={{marginLeft: 10, border: '1px solid #c4c4c4', fontSize: 12, borderRadius: 5, width: 120, height: 32}}>
                        <option value={'all'}>
                            TIPO USUARIO
                        </option>
                        {
                            Object.keys(usersRolesGroup).sort((a, b) => {
                                if (a < b) {
                                    return -1
                                }
                                if (a > b) {
                                    return 1
                                }
                                return 0
                            }).map((key, index) => {
                                return(
                                    <option key={index} value={key}>
                                        {/* key === 'undefined' ? 'SIN INFORMACIÓN' :  */key.toUpperCase()}
                                    </option>
                                )
                            })
                        }
                    </select>}
                </div>
                <div style={{width: '100%', textAlign: 'left', padding: 20}}>
                    <IonGrid>
                        <IonRow style={{borderBottom: '1px solid #ccc', marginRight: 20}}>
                            <IonCol size='3'>
                                <p>Nombre</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Ciudad</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Tipo Usuario</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Especialidad</p>
                            </IonCol>
                            <IonCol size='1' style={{textAlign: 'center'}}>
                                <p>Estado</p>
                            </IonCol>
                            <IonCol size='2'>
                                <p>Acciones</p>
                            </IonCol>
                        </IonRow>
                        <div style={{
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            fontSize: 14
                        }}>
                        {
                            usersGroup && usersGroup[pageSelected - 1] && usersGroup[pageSelected - 1].map((user: any, i: number) => {
                                if (user._id === userId) {
                                    null
                                } else {
                                    return (
                                        <IonRow key={`${user.name}_${i}`}>
                                            <IonCol size='3'>
                                                <p>{user.name} {user.lastName}</p>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <p>{user.city}</p>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <p>{user.roles && user.roles[0] && user.roles[0].name}</p>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <p>{user.section && user.section[0] && user.section[0].name}</p>
                                            </IonCol>
                                            <IonCol size='1'>
                                                <div style={user.state ? 
                                                    {margin: '16px 10px', height: 24, textAlign: 'center', border: '1px solid green', borderRadius: 3, color: 'green'} : 
                                                    {margin: '16px 10px', height: 24, textAlign: 'center', borderRadius: 3, backgroundColor: '#ccc', color: 'grey'}}>
                                                    <p style={{margin: '4px 0px'}}>{user.state ? 'ACTIVA' : 'INACTIVA'}</p>
                                                </div>
                                            </IonCol>
                                            <IonCol size='2'>
                                                <IonRow>
                                                    <IonCol size='3'>
                                                        <button onClick={() => {
                                                            getUser(user)
                                                            setOpenUserProfile(true)
                                                        }} style={{backgroundColor: 'transparent', margin: '16px 10px'}}>
                                                            <img src={Edit} style={{height: 24}} />
                                                        </button>
                                                    </IonCol>
                                                    <IonCol size='3'>
                                                        <button onClick={() => {
                                                            if (user.roles && user.roles[0] && (user.roles[0].name === 'SuperAdmin')) {
                                                                alert('Super Administrador no puede ser borrado')
                                                            } else if (user.roles && user.roles[0] && (user.roles[0].name === 'admin')) {
                                                                alert('Administrador no puede ser borrado')
                                                            } else {
                                                                if (confirm(`¿Desea eliminar al usuario ${user.name} ${user.lastName}?\nSi desea revertir el borrado contáctese con el administrador de la plataforma.`)) {
                                                                    deleteUser(user._id)
                                                                }
                                                            }
                                                        }} style={{backgroundColor: 'transparent', margin: '16px 10px'}}>
                                                            <img src={Trash} style={{height: 24}} />
                                                        </button>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
    
                                        </IonRow>
                                    )
                                }
                            })
                        }
                        {
                            !usersGroup && 
                            <div style={{width: '100%'}}>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                                <IonSkeletonText animated={true} style={{ width: '100%', height: 50.4 }}></IonSkeletonText>
                            </div>
                        }
                        </div>
                    </IonGrid>
                </div>
                <div style={{position: 'absolute', bottom: 30, left: 0, width: '100%', textAlign: 'right', paddingRight: 30}}>
                {usersGroup && <Pagination
                    id={'pagination-user'}
                    onChange={(e, page) => {setPageSelected(page)}}
                    page={pageSelected}
                    defaultPage={1}
                    style={{position: 'absolute', right: 20, bottom: 60}}
                    count={Object.keys(usersGroup).length}
                    color="primary" />}
                    <button onClick={handleClose} className="ion-activatable ripple-parent rectangle" style={{width: 120, height:48, marginLeft: 'auto', marginRight: 10, borderRadius: 10, backgroundColor: '#D4D5D9', color: '#fff'}}>
                        Salir
                    </button>
                    {/* <button disabled className="ion-activatable ripple-parent rectangle" style={{width: 180, height:48, marginLeft: 'auto', marginRight: 'auto', borderRadius: 10, backgroundColor: '#41507A', color: '#fff'}}>
                        + Crear Nuevo Usuario
                    </button> */}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default UsersManagementModal
