import { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { io, Socket } from "socket.io-client";
import { useAuthContext } from './Auth.context';
import { useMessagesContext } from './Messages.context';

interface SocketType {
    notification: {
        isData: boolean,
        data: any
    },
    emitNotificationToUser: (data: any) => Promise<any>
    listenPDF: (userId: string, getDataPDF: any) => void
    waitAudio: any
    setWaitAudio: any
    messageAudio: any
    setMessageAudio: any
    source: AudioBufferSourceNode | undefined
    idMessage: number | undefined
    setAvatarSpeaking: Dispatch<SetStateAction<boolean>>
    avatarSpeaking: boolean
}

export const SocketContext = createContext<SocketType>({} as SocketType)

export const SocketProvider = (props: any) => {
    const {user} = useAuthContext()
    const {setMessageAudioUser} = useMessagesContext()
    const urlLocal = window.location.host.split('.')[0].includes('qa') ? process.env.REACT_APP_SERVER_URL_QA : window.location.host.split('.')[0] === 'app' ? process.env.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL_TEST
    const [notification, setNotification] = useState<{
        isData: boolean,
        data: any
    }>()
    const [socketLocal, setSocketLocal] = useState<Socket>()
    const [messageAudio, setMessageAudio] = useState<any>()
    const [idMessage, setIdeMessage] = useState<number>()
    const [waitAudio, setWaitAudio] = useState({
        state: false,
        time: ''
    })
    const [source, setSource] = useState<AudioBufferSourceNode>()

    const [avatarSpeaking, setAvatarSpeaking] = useState(false)

    const [audioList, setAudioList] = useState<any[]>([])


    const audioContextRef = useRef<AudioContext | null>(null);
    const audioContext = new AudioContext();

    useEffect(() => {
        if (urlLocal) {
            setSocketLocal(io(urlLocal))
        }
    }, [urlLocal])

    useEffect(() => {
        if (source) {
            source.start(0)
            console.log(Date.now())
            setAvatarSpeaking(true)
            source.addEventListener('ended', (ev) => {
                console.log('Terminado')
                setAvatarSpeaking(false)
            })
        }
    }, [source])

    /* useEffect(() => {
        console.log(audioList)
    }, [audioList]) */

    /* const executeAudioList = async () => {
        if (audioList.length > 0) {
            const decodedData = audioContext.decodeAudioData(opus)
            const sourceTemp = new AudioBufferSourceNode(audioContext);
            sourceTemp.buffer = await decodedData;
            sourceTemp.connect(audioContext.destination);
            setSource(sourceTemp)
        }
    } */

    /* const addAudioTolist = (idMessage: any, opus: any) => {
        const audioListCache = [...audioList, {audio: opus, idMessage: idMessage}]
        setAudioList(audioListCache)
    } */

    useEffect(() => {
        if (socketLocal && user) {
            try {
                audioContextRef.current = audioContext;
                socketLocal!.on(`audio_buffer_${user._id}`, async (data) => {
                    console.log(data)
                    setIdeMessage(Number(data.idMessage))
                    const opus = data.audioArrayBuffer
                    const decodedData = audioContext.decodeAudioData(opus)
                    const sourceTemp = new AudioBufferSourceNode(audioContext);
                    sourceTemp.buffer = await decodedData;
                    sourceTemp.connect(audioContext.destination);
                    setSource(sourceTemp)
                    /* addAudioTolist(idMessage, opus) */
                })
                socketLocal!.on(`audio_buffer_end_${user._id}`, () => {
                    console.log("Transmisión completada");
                })
            } catch (error) {
                console.log(error)
            }
            socketLocal!.on(`audio${user._id}`, data => {
                console.log(data)
                setMessageAudio(data)
            })
            socketLocal!.on(`messageAudio${user._id}`, data => {
                console.log(data.newUserMessage)
                const messageFromServer = data.newUserMessage
                setMessageAudioUser(messageFromServer)
            })
            
        return () => {
            socketLocal!.off(`audio_buffer_${user._id}`);
            socketLocal!.off(`audio_buffer_end_${user._id}`);
            audioContext.close();
        };
        }
    }, [socketLocal, user])    

    const values = {
        notification,
        waitAudio,
        setWaitAudio,
        messageAudio,
        setMessageAudio,
        source,
        idMessage,
        setAvatarSpeaking,
        avatarSpeaking
    } as SocketType

    return (
        <SocketContext.Provider value={values}>
            {props.children}
        </SocketContext.Provider>
    )
}

export const useSocketContext = () => useContext(SocketContext)